@use 'breakpoints' as *;

:root {
    --font-sansserif: 'Acumin Pro Wide', sans-serif;
    --font-serif: 'Minion Pro', serif;
}

@mixin headings {
    @for $index from 1 through 6 {
        h#{$index} {
         @content;
      }
    }
}

@include headings{
    @extend %headings !optional;
    font-size: var(--root-fontsize);
    font-weight: normal;
    line-height: inherit;
    margin: 0;
}

$font-primary: var(--font-sansserif);
$font-secondary: var(--font-serif);

%font-base {
    font-family: $font-primary;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.4;
}

/* ---- sans ---- */

%font-primary-2xs {
    @extend %font-base;
    font-size: 1.2rem;
    line-height: 1.2;
}

%font-primary-2xs-uppercase {
    @extend %font-primary-2xs;
    letter-spacing: -0.5px;
    text-transform: uppercase;
}

%font-primary-xs {
    @extend %font-base;
    font-size: 1.6rem;
    line-height: 1.2;
    @include breakpoint(tablet-landscape) {
        font-size: 1.2rem;
    }
}

%font-primary-s {
    @extend %font-base;
    font-size: 1.6rem;
}

%font-primary-s-medium-uppercase {
    @extend %font-primary-s;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.5px;
}

%font-primary-s-medium {
    @extend %font-primary-s;
    font-weight: 600;
}

%font-primary-m {
    @extend %font-base;
    font-size: 2.2rem;
    line-height: 1.2;
}

%font-primary-l {
    @extend %font-base;
    font-size: 2.2rem;
    @include breakpoint(tablet-landscape) {
        font-size: 3rem;
    }
}

%font-primary-xl {
    @extend %font-base;
    font-size: 3rem;
}

%font-primary-2xl {
    @extend %font-base;
    font-size: 4rem;
}

%font-primary-3xl {
    @extend %font-base;
    font-size: 4rem;
    @include breakpoint(tablet-landscape) {
        font-size: 7rem;
        line-height: 1.1;
    }
}

/* ---- serif ---- */

// copy
%font-secondary-m {
    font-family: $font-secondary;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.4;
}

%font-secondary-l {
    font-family: $font-secondary;
    font-size: 4rem;
    line-height: 1.1;
}

%font-secondary-xl {
    font-family: $font-secondary;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.1;
    @include breakpoint(tablet-landscape) {
        font-size: 7rem;
    }
}

// spacific cases font extensions

%font-header-menu {
    @extend %font-primary-m;
    @include breakpoint(tablet-landscape) {
        font-size: 1.6rem;
    }
}