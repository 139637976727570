@use '../helpers' as *;

body.page--odv {
    .page-main {
        gap: 0;
    }
    .page-headings {
        max-width: none;
        text-align: left;
        padding: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: 1fr;
        max-height: 90vh;
        .wp-block-image {
            position: relative;
            z-index: 1;
            grid-row: 1 / -1;
            grid-column: 1 / -1;
            height: 100%;
            &::after {
                position: absolute;
                content: '';
                z-index: 2;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 75%);
            }
            picture {
                height: 100%;
            }
            img {
                border-radius: 0;
                height: 100%;
                object-fit: cover;
            }
        }
        .page-title {
            @extend %font-secondary-xl;
            color: var(--white);
            grid-row: 2 / -1;
            grid-column: 1 / -1;
            z-index: 2;
            padding: 2.4rem;
            @include breakpoint(tablet-landscape) {
                max-width: 40vw;
                margin-left: 15vw;
            }
        }
    }

    .page-body {
        @include breakpoint(tablet-landscape) {
            padding-top: 3.2rem;
        }
    }

    .page-intro {
        .page-excerpt {
            @extend %font-primary-m;
            padding: 2rem 0;
        }
        @include breakpoint(tablet-landscape) {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 2.4rem;
        }
    }

    .card.card--focus {
        .card-pretitle {
            @extend %font-primary-s-medium-uppercase;
        }
    }

    .odv-note {
        @extend %font-primary-xs;
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
        max-width: var(--reading-width);
        padding: 2.4rem;
        border-radius: var(--border-radius-m);
        background-color: var(--color-background-primary);
    }

    .footer {
        @extend %font-primary-m;
        .footer-inner {
            &.footer-mainarea {
                @include breakpoint(tablet-landscape) {
                    justify-content: center;
                }
            }
        }
    }
}